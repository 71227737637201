import React, { useEffect, useState, useMemo } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "gatsby";
import SearchButtons from "../searchButtons";
import ListTags from "../listTags";
import { SingleProjectFrontMatter } from "types/graphql";
import ArrowRight from "assets/svg/arrow-right.svg";
import classNames from "classnames/bind";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {capitalizeFirstLetter} from 'assets/js/utils'

type ProjectsProps = {
  projects: SingleProjectFrontMatter[]
}

const Projects = ({ projects }: ProjectsProps) => {
  const [filters, setFilters] = useState({
    sectors: "Tous",
    tags: "Tous types",
  });

  const rawSectors = [
    "Tous",
    ...new Set(
      projects.map((project) => project.node.frontmatter.sectors).sort()),
  ].flat();

  const rawTags = [
    'Tous types',
    ...new Set(
      projects.map((project) => project.node.frontmatter.tags).sort()),
  ].flat();

  const tags = [...new Set(rawTags.map((tag) => capitalizeFirstLetter(tag.toLowerCase())))];

  const sectors = [...new Set(rawSectors.map((sector) => capitalizeFirstLetter(sector.toLowerCase())))];

  const filteredProjects = useMemo(() => {
    if (
      filters.sectors === "Tous" &&
      filters.tags === "Tous types"
    ) {
      return projects;
    }
    return projects.filter(
      (project) => {
        if (filters.sectors === "Tous") {
          return project.node.frontmatter.tags.some(element => element.toLowerCase() === filters.tags.toLowerCase());
        } else if (filters.tags === "Tous types") {
          return project.node.frontmatter.sectors.some(element => element.toLowerCase() === filters.sectors.toLowerCase());
        } else {
          return project.node.frontmatter.tags.some(element => element.toLowerCase() === filters.tags.toLowerCase()) && project.node.frontmatter.sectors.some(element => element.toLowerCase() === filters.sectors.toLowerCase());
        }
      })
  }, [filters]);

  const delayStyle = {
    transitionDelay: '0.15s'
  }

  return (
    <section>
      <SearchButtons
        filters={filters}
        tags={tags}
        sectors={sectors}
        onChange={(event) => setFilters(event)}
      />
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={filters.tags + '-' + filters.sectors}
          timeout={200}
          classNames='page-fade'
        >
          <div>
            {filteredProjects.map((project, index: number) => {
              const {
                node: {
                  frontmatter: {
                    title,
                    description,
                    sectors,
                    tags,
                    slug,
                    imageBg,
                    imageBg_alt,
                  },
                },
              } = project;

              let imgBg = getImage(imageBg)

              const reverse = index % 2 != 0;
              return (
                <Link key={index} to={`/projets/${slug}`}>
                  <article
                    className="grid grid-cols-1 md:grid-cols-16 items-center border-t border-gray-100 px-4 md:px-0 py-4 md:py-0 mb-8 md:mb-0 gap-y-3 min-h-[300px]"
                  >
                    <div
                      className={classNames("lg:row-start-1 ", {
                        "md:col-start-10 md:col-span-full md:col-end-16 lg:col-end-15": reverse,
                        "md:col-start-2 lg:col-start-3 md:col-span-6 ": !reverse,
                      })}
                    >
                      <p className="caption2 text-gray-600 uppercase mb-4">
                        {title}
                      </p>
                      <h2 className="mb-5 hover:text-gray-700">{description}</h2>

                      <ul className="flex flex-wrap gap-2 overflow-hidden">
                        <ListTags tags={[...tags, ...sectors]} />
                      </ul>
                    </div>
                    <div className={classNames(
                      "md:col-span-7 md:row-start-1 relative z-10 overflow-visible",
                      {
                        "md:col-start-1": reverse,
                        "md:col-end-17": !reverse,
                      }
                    )}>
                      <figure
                        data-aos="custom"
                        className={classNames(
                          "transition-image",

                        )}
                      >
                        <GatsbyImage
                          image={imgBg}
                          alt={imageBg_alt}
                          className="h-48 md:h-80 lg:h-project 3xl:h-150 object-cover w-full"
                        />
                        <span className="transition-layer" style={delayStyle}/>
                      </figure>
                      <span className={classNames(
                            "bg-yellow arrow-right rounded-full w-16 h-16 mt-2 absolute right-3 -bottom-8  md:top-8 block",
                            {
                              "md:-right-9": reverse,
                              "md:-left-9": !reverse,
                            }
                          )}>
                          <ArrowRight className="p-4 w-full" />
                      </span>
                    </div>
                  </article>
                </Link>
              );
            })}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </section>
  );
};

export default Projects;
