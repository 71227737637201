import React from "react";

interface Props {
  tags: string[];
}

const listTags = ({ tags }: Props) => {

  const getColor = (type: string) => {
    type = type.toLowerCase()
    const colors: { [key:string]:string } = {
      "app mobile": "bg-black text-white",
      "webapp": "bg-black text-white",
      "site web": "bg-black text-white",
      "site e-commerce": "bg-black text-white",
      "site vitrine": "bg-black text-white",
      asap: "bg-yellow text-gray-900",
      date: "bg-gray-50 text-gray-600",
      secteur: "bg-gray-100 text-gray-700",
      alternance: "bg-yellow text-gray-900",
      cdi: "bg-green-500 text-gray-900",
      cdd: "bg-pink text-gray-900",
    };
    return colors[type] || colors["secteur"];
  }

  return <>{ tags.map((item: string, index: number) => {
    return (
      <li
        key={index}
        data-aos="fade-up"
        style={{transitionDelay: `${(index * 0.1) }s`}}
        className={
          "caption3 rounded-full py-1 px-2 uppercase select-none inline-block " +
          getColor(item)
        }
      >
        {item}
      </li>
    );
  }) }</>;
};
export default listTags;
