import React, {useState} from "react";
import CustomSelect from "components/customSelect";
import {moveOthersToEnd} from 'assets/js/utils'

interface Props {
  tags: string[];
  sectors: string[];
  filters:{
    sectors: string;
    tags: string;
  }
  onChange: (event: {
    sectors: string,
    tags: string
  }) => void;
}

const SearchButtons = ({ tags, sectors, filters, onChange } : Props  ) => {
  const [selectedOptionTag, setSelectedOptionTag] = useState<number|string >(0);

  let initialIndex= sectors.findIndex(v => v == 'Autres')

  moveOthersToEnd(sectors, initialIndex, sectors.length-1)

  const handleCustomSelectTags = (index:number) => {
    setSelectedOptionTag(index)
    let goodName = tags[index]
    onChange({
      ...filters,
      'tags': goodName
    })
  };

  const handleSelectTags = (event: { target: { value: string; name: string }; }) => {
    setSelectedOptionTag(+event.target.value)
    let goodName = tags[+event.target.value]
    onChange({
      ...filters,
      'tags': goodName
    })
  };

  const handleSelectChange = (event: { target: { value: string; name: string }; }) => {
    onChange({
      ...filters,
      [event.target.name]: event.target.value
    })
  };

  const handleSectorChange = (sectors:string) => {
    onChange({
      ...filters,
      sectors
    })
  };

  return (
    <div className="flex flex-wrap lg:flex-nowrap justify-between items-center mb-6 lg:px-20 px-3 gap-x-6 lg:gap-x-10">
      <ul className="hidden lg:flex lg:flex-wrap overflow-x-auto gap-y-3 gap-x-4 pb-2 lg:pb-0 apperance-none">
        {
          sectors.map((sector, index) => (
            <li key={index} className="flex-00a">
              <button
                className={
                  "text-b3 px-2.5 rounded-sm " +
                  (filters.sectors === sector ? "bg-gray-100" : undefined)
                }
                onClick={() => handleSectorChange(sector)}
              >
                {sector}
              </button>
            </li>
          ))
        }
      </ul>
      <select
        name="sectors"
        id="sectors"
        className="form-select border-2 border-gray-100 py-1.5 px-2 placeholder:text-gray-500 lg:hidden w-full lg:w-fit flex-1 lg:flex-none focus:outline-none focus:border-yellow h-11"
        onChange={handleSelectChange}>
        {
          sectors.map((option:string, index: number) => (
            <option data-index={index + 1} key={index} value={option}>
              {option}
            </option>
          ))
        }
      </select>

      <CustomSelect containerClassName={"hidden lg:block"} buttonClassName={"w-max"} value={selectedOptionTag} options={tags} onClick={handleCustomSelectTags}/>

      <select
        id="type-select"
        name="tags"
        value={selectedOptionTag}
        className="form-select border-2 border-gray-100 py-1.5 px-2 placeholder:text-gray-500 w-full lg:hidden lg:w-fit flex-1 lg:flex-none focus:outline-none focus:border-yellow h-11"
        onChange={handleSelectTags}
      >
        {
          tags.map((option:string, index: number) => (
            <option data-index={index + 1} key={index} value={index}>
              {option}
            </option>
          ))
        }
      </select>
    </div>
  );
};

export default SearchButtons;
