import React from "react";

// the interface needs to explicitly declare which strings are safe to pass
interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
}

const Heading = ({ headingLevel = "p", children, className }: HeadingProps) => {
  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(headingLevel, props, children);

  return <Heading className={className} data-aos="custom" >{children}</Heading>;
};

export default Heading;
