import React from "react";
import { HeroFrontMatter } from "types/graphql";
import TextHero from "components/TextHero";
import classNames from "classnames/bind";

type herotextProps = Omit<HeroFrontMatter, "format"> & {
  content?: string;
  className?: string;
  index?: number;
  children?: React.ReactNode
  withTransition?: boolean
};

const heroImage = ({
                     imageUrl,
                     title,
                     index,
                     buttonlabel,
                     withTransition = true,
                     cardJob,
                     content,
                     className,
                     children,
                     big = false
                   }: herotextProps) => {
  const transitionStyle = {
    transitionDelay: index! <= 2 ? '0.4s' : '0s'
  }
  return (
    <section className={classNames(className, {
      "h-52 lg:h-100 3xl:h-[30rem]": !title && !big,
      "md:h-64 lg:h-100 3xl:h-[40rem]": title && !big,
      "h-52 md:h-64 lg:h-150 3xl:h-[64rem] 4xl:h-[100rem]": big && !cardJob,
      "lg:h-150 3xl:h-[64rem] 4xl:h-[100rem]": cardJob,
      },
    )}
    >
      <div
        data-scroll={withTransition ? true : false}
        className={classNames("relative bg-cover bg-center bg-no-repeat h-full w-full flex flex-col justify-center items-center py-12 lg:py-24 px-5",{
          overlay: title,
          "transition-image": withTransition
          })
        }
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <TextHero title={title} buttonlabel={buttonlabel} cardJob={cardJob} content={content}> {children} </TextHero>
        <span className="transition-layer" data-key={index} style={transitionStyle}/>
      </div>
    </section>
  );
};

export default heroImage;
