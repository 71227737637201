import Heading from "components/headings";
import BaseButton from "components/button/BaseButton";
import {graphql, Link, useStaticQuery} from "gatsby";
import ArrowRight from "assets/svg/arrow-right.svg";
import React, {ReactNode} from "react";
import ListTags from "components/listTags";


type Props = {
  title?: string
  buttonlabel?: string
  cardJob?: boolean
  children: ReactNode
  content?: string
}
const TextHero = ({title, buttonlabel , cardJob, children, content}: Props) => {
    const queryJob = graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(/jobs/job/)/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              slug
              tags
            }
          }
        }
      }
    }
    `;

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("carrieres-join-us");
    }
  }

  const CardJob = () => {
    if (cardJob) {
      const jobCard = useStaticQuery(queryJob);
      const {
        allMarkdownRemark: { edges: cards },
      } = jobCard;
      return (
        <ul className="flex flex-col lg:flex-row gap-4 my-6 justify-center items-center">
          {
            cards.map(({ node: { frontmatter: element } } : { node : { frontmatter: { slug: string, tags: string[], title: string}}}, index: number) => {
              return (
                <li
                  className="card-job rounded-xl p-3 bg-transparent-80 hover:bg-white lg:w-72 text-left relative transform transition duration-03 "
                  key={index}
                >
                  <span className="h4 mb-4 text-gray-900 font-display block" dangerouslySetInnerHTML={{ __html: element.title }}/>
                  <ul className="flex gap-x-2 overflow-hidden">
                    {element.tags && <ListTags tags={element.tags.slice(0, 3)} />}
                  </ul>
                  <Link to={`/carrieres/${element.slug}`} className="stretched-link" />
                </li>
              );
            })}
        </ul>
      );
    }
    return null;
  };

  if (typeof title !== "undefined") {
    return (
      <div className="flex flex-col justify-center text-center text-white z-1 w-full">
        <Heading className="mb-4" headingLevel={"h2"}>
          {title}
        </Heading>
        {
          content && (
            <div
              className="text-b2 mb-6"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )
        }
        {children}
        {cardJob && <CardJob/>}
        {buttonlabel && (
          <BaseButton
            onClick={() => handleClickPlausible()}
            tag={Link}
            to={`/contact?type=${cardJob ? 'job' : 'projet'}`}
            target="_blank"
            rel="noopener"
            role="button"
            className="self-center arrow-left-in"
          >
            <ArrowRight />
            {buttonlabel}
          </BaseButton>
        )}
      </div>
    );
  }
  return null;
};
export default TextHero;
