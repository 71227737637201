import React from "react";
import {Seo} from "components/seo";

import { graphql } from "gatsby";
import { projectsQuery } from "types/graphql";

import TitleIntro from "components/sections/titleIntro";
import HeroImage from "components/sections/hero";
import ProjectsList from "components/sections/projects";

export default function Projects({ data }: { data: projectsQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
    projects: { edges: projectsData },
  } = data;

  return (
    <>
      {sectionsData.map(
        ({ node: { frontmatter: elem, html } }, index: number) => {
          if (elem.format == "title") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription} />
                <TitleIntro title={elem.title} content={html} className={"lg:pb-16"} />
              </React.Fragment>
            );          }
          if (elem.format == "projects") {
            return <ProjectsList key={index} projects={projectsData} />;
          }
          if (elem.format == "heroproject") {
            return (
              <HeroImage
                key={index}
                title={elem.title}
                content={html}
                withTransition={false}
                buttonlabel={elem.buttonlabel}
                imageUrl={elem.imageUrl}
              />
            );
          }
        }
      )}
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/projects/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            titles
            imageUrl
            buttonlabel
            listicon
            listtitle
            listcontent
            cardJob
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/(/projects/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            title
            description            
            imageBg {
              childImageSharp {
                gatsbyImageData(
                  width: 592
                )
              }
            }
            imageBg_alt
            sectors
            tags
            testimonialName
            testimonialRole
          }
        }
      }
    }
  }
`;
